//TODO: Remove or make into real progress tracker
/*@ngInject*/ function ProgressController ($log, $scope) {
  console.info('loaded ProgressController');
  $scope.$on('sq.serverEvents.service:serverDown', () => {
    this.interrupted = true;
  });

  $scope.$on('sq.serverEvents.service:serverUp', () => {
    this.interrupted = false;
  });

}

angular.module('sq.jobs.progress.component', [])
.component('sqProgress', {
  bindings: {
    // The submission object (could be search or main job submission)
    jobSubmission: '<',
    progressSize: '@',
  }, // isolate scope
  template: `
    <span layout='row' layout-align='start center' style='width:100%'>
      <md-progress-circular ng-show='$ctrl.jobSubmission.state === "started" ||
      $ctrl.jobSubmission.state === "submitted"'></md-progress-circular>
      <span>
      <strong style='margin-left:10px' ng-bind='$ctrl.jobBeingViewed.submission.log.progress | number'>
      </strong>
      sites annotated
      </span>
      <span flex></span>
      <span ng-if='$ctrl.jobBeingViewed.isIncomplete()'>
        <md-button class=" md-icon-button"
        ng-click='$ctrl.checkStatus()'>
          <span ng-switch='$ctrl.checkingStatus'>
            <md-icon ng-switch-when='false' class='material-icons'>refresh</md-icon>
            <span ng-switch-when='true'>
              <span ng-switch='$ctrl.checkingStatusSuccess'>
                <md-progress-circular ng-switch-when='false' md-diameter='30'></md-progress-circular>
                <md-icon ng-switch-when='true' class='material-icons'>done</md-icon>
              </span>
            </span>
          </span>
        </md-button>
        <md-tooltip>Refresh Status</md-tooltip>
      </span>
      </span>`,
  controller: ProgressController,
});
